@import 'src/styles/colors';

.divider {
  position: relative;
  width: 100%;
  height: 1px;
  margin: 20px 0;
  border: none;
  border-top: 1px solid $cloud;
  overflow: hidden;
  box-sizing: border-box;
}

// border styles
.dashed {
  border-top-style: dashed;
}

.dotted {
  border-top-style: dotted;
}

.solid {
  border-top-style: solid;
}

// border colors
.light {
  border-top-color: $cloud;
}

.lighter {
  border-top-color: $slate;
}

.dark {
  border-top-color: $stone;
}

.darker {
  border-top-color: $pebble;
}
