@import '../../../../styles/colors';
@import '../../../../styles/layout';

//.header {
//  background-color: $darkBlue;
//  height: 98px;
//  position: sticky;
//  top: 0;
//  left: 0;
//  right: 0;
//  z-index: 100;
//  width: 100%;
//  color: white;
//
//  .header-wr {
//    display: flex;
//    max-width: $max-content-width;
//    margin: auto;
//    padding: 0 10px;
//    height: 100%;
//  }
//}
//
//.logo-container {
//  padding: 0;
//  position: static;
//  transform: translate(0, 0);
//  display: flex;
//  align-items: center;
//
//  @media screen and (min-width: $screen-md) {
//    position: absolute;
//    top: 50%;
//    left: 25px;
//    transform: translate(0, -50%);
//  }
//
//  img {
//    height: 100%;
//    width: 90px;
//  }
//}
 .app-bar {
   background-color: $alabaster !important;

   @media screen and (min-width: 880px) {
     background-color: $medBrown !important;
   }
 }

.toolbar {
  width: 90%;
  margin: auto;

  .mobile-header {
    height: 130px;

    @media screen and (min-width: 880px) {
      display: none;
    }
  }

  .desktop-menu {
    display: none;

    @media screen and (min-width: 880px) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 4em !important;
    }
  }
}

.navbar-item {
 color: $alabaster;
}

.logo-banner {
 display: none;

  @media screen and (min-width: 880px) {
   display: block;
   background-color: $darkBrown;
   margin-top: 64px;
   height: 140px;
   text-align: center;
   padding: 20px 0;
  }

}

.mobile-logo {
  margin-top: 10px;
  text-align: center;
  img {
    width: 110px;
  }

  @media screen and (min-width: 880px) {
    display: none;
  }
}

.mobile-menu {
  button {
    position: absolute;
    transform: scale(1.25);
    top: 45px;
    right: 10px;

    @media screen and (min-width: 880px) {
      display: none;
    }
  }
}

.mobile-menu-list {
  padding-right: 3em !important;

  li {
    padding-top: 2em;
  }
}
