@import '../../../styles/colors';
@import '../../../styles/layout';

.wr {
  max-width: $max-content-width;
  margin: auto;
  padding-top: 3em;
}

.gallery-image {
  width: 100%;
  text-align: center;

  img {
    max-width: 100%;
  }
}

.gallery {
  min-height: 600px;
}

