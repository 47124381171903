/* Global Layout Styles */
$max-content-width: 974px;

/* Screen width breakpoints */
$screen-xs: 400px;
$screen-sm: 720px;
$screen-md: 1024px;
$screen-lg: 1360px;

/* Typography */
$small-font: .875em;
