.contact-info {
  p {
    text-align: center;
  }
}

.indicates-required {
  margin-bottom: 1em;
}

.submit-button {
  height: 36px;
  font-size: 1em;
}
