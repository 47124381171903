.store-image {
  text-align: center;

  img {
    width: calc(100vw - 10%);
    @media screen and (min-width: 600px) {
      width: 30em;
    }
  }
}
.etsy-link {
  text-align: center;
}
