@import 'src/styles/layout';
@import 'src/styles/colors';

.page-wr {
  width: 100%;
  padding: 2em 0 4em;
}

.page-container {
  margin-top: 90px;

  @media screen and(min-width: 880px) {
    max-width: $max-content-width;
    margin: auto;
    display: flex;
    justify-content: flex-start;
  }
}

.main {
 display: flex;
  flex-direction: column;
  flex: 3;
  background-color: $alabaster;
  color: $pebble;
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $lightBrown;
  color: $white;
  align-items: center;

  h2 {
    padding-bottom: .7em;
    padding-top: .3em;
    border-bottom: 2px solid $alabaster;
  }

  .sidebar-section {
    width: 100%;

    .sidebar-section-content {
      margin-bottom: 2em;
    }

    a {
      color: #fff;

      &:hover {
        color: #000;
        font-weight: bold;
      }
    }
  }
}

.main, .sidebar {
  padding: 1em 1em 3em;
}

h1 {
  margin-bottom: 0;
  margin-top: .5em;
}

.fb-share-button {
  margin-bottom: 1em;
}
