.section-1 {
  img {
    float: left;
    padding: 1em 1em 0 0;
  }
}
.section-2 {
  img {
    float: right;
    padding: 1em 1em 0 0;
  }

}
.section-3 {
  text-align: center;

}
