@import '../../../../styles/colors';
@import '../../../../styles/layout';

.footer {
  background-color: $darkBrown;
  border-top: 1px solid $darkBrown;
  padding-top: 1.5em;

  @media screen and (min-width: $screen-sm){
    padding-top: 0;
  }

    .container {
    max-width: $max-content-width;
    margin: auto;
    font-size: $small-font;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $screen-sm){
      flex-direction: row;
      padding: 0 1em;
    }
  }

  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $alabaster;
  }

  .copyright {
    margin: 2em 0;

    @media screen and (min-width: $screen-sm){
      flex: 1;
      align-items: center;
    }

    p {
      margin: 0;
    }
  }
}
